import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { PageListing } from "components"

const MDXCustomRenderer = ({ data }) => (
  <>
    <PageListing pages={data.allMdx.edges} />

    <MDXRenderer frontmatter={data.mdx.frontmatter}>
      {data.mdx.body}
    </MDXRenderer>
  </>
)

export default MDXCustomRenderer

export const query = graphql`
  query($id: String, $slug: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      fields {
        slug
        crumbs {
          label
          path
        }
      }
      frontmatter {
        title
      }
    }
    allMdx(filter: { fields: { dir: { eq: $slug } } }) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
